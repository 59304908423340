import React, { useEffect, useRef } from "react";
import { FaGithub, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { RiFacebookLine, RiLinkedinLine } from "react-icons/ri";
import img from "../../assets/hero.webp";
import WobbleText from "../animations/WobbleText";
import { gsap } from "gsap";
import "./hero.scss";
import { content } from "../../static/Content";
const Hero = () => {
  const leftRef = useRef(null); // Reference for the left section
  const rightRef = useRef(null); // Reference for the right section
  const buttonRef = useRef([]); // Reference for buttons
  const iconsRef = useRef([]); // Reference for icons

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { duration: 1, ease: "ease-out" } });

    // Animate entire left section
    tl.fromTo(leftRef.current, { y: -100, opacity: 0 }, { y: 0, opacity: 1 })
      .fromTo(
        rightRef.current,
        { y: 100, opacity: 0 },
        { y: 0, opacity: 1 },
        "<"
      ) // Animate right section after left
      .fromTo(
        buttonRef.current,
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, stagger: 0.1 },
        "<0.2"
      )
      .fromTo(
        iconsRef.current,
        { y: -100, opacity: 0 },
        { y: 0, opacity: 1, stagger: 0.1 },
        "<0.2"
      );
  }, []);

  return (
    <div className="hero-parent parent scroll-section">
      <div className="hero-container container">
        <div className="hero-left" ref={leftRef}>
          <h2 className="hero-title">
            <div className="nowrap-text">
              Hello I'am{" "}
              <div className="bold">
                <WobbleText text="Md" /> <WobbleText text="Zaigham." />
              </div>
            </div>
            <br />
            <span className="nowrap-text">
              Software{" "}
              <div className="outlined-text">
                <WobbleText text="Developer" />
              </div>
            </span>
            <br />
            Based In{" "}
            <div className="bold">
              <WobbleText text="India." />
            </div>
          </h2>
          <p className="hero-description">{content.hero_description}</p>

          <div className="hero-icons">
            <a
            href={content.linkedin_link}
            target="__blank"
              className="linkedin icon"
              ref={(el) => (iconsRef.current[0] = el)}
            >
              <RiLinkedinLine />
            </a>
            <a
            href={content.facebook_link}
            target="__blank"
              className="facebook icon"
              ref={(el) => (iconsRef.current[1] = el)}
            >
              <RiFacebookLine />
            </a>
            <a
            href={content.instagram_link}
            target="__blank"
              className="instagram icon"
              ref={(el) => (iconsRef.current[2] = el)}
            >
              <FaInstagram />
            </a>
            <a
              href={`https://wa.me/${content.contact_number}?text=Hello%20Md%20Zaigham,%20I'm%20interested%20in%20your%20services!`}
              target="_blank" // Opens in a new tab
              rel="noopener noreferrer" // Security best practice
              className="whatsapp icon"
              ref={(el) => (iconsRef.current[3] = el)}
            >
              <FaWhatsapp />
            </a>
            <a
            href={content.github_link}
            target="__blank"
              className="whatsapp icon"
              ref={(el) => (iconsRef.current[4] = el)}
            >
              <FaGithub />
            </a>
          </div>
        </div>
        <div className="hero-right" ref={rightRef}>
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
