import pandoza from "../assets/company/pandoza.webp"
import crm from "../assets/projects/crm.png"
import hrms from "../assets/projects/hrms.png"
import movie from "../assets/projects/movie.webp"
export const content = {
  first_name: "Md",
  last_name: "Zaigham",
  resume_link:
    "https://drive.usercontent.google.com/u/0/uc?id=1EUkhLizJZUIoTkvmCiKOU3HOPbKiRsC-&export=download",
  linkedin_link: "https://www.linkedin.com/in/md-zaigham-97b7ba259/",
  facebook_link: "https://www.facebook.com/ZaighamBoss?mibextid=ZbWKwL",
  instagram_link:
    "https://www.instagram.com/md_zaigham/profilecard/?igsh=NnpxOWcwMjlqa2Fu",
  contact_number: "+918210272603",
  email: "mdzaigham27@gmail.com",
  github_link: "https://github.com/ZaighamCodes",
  hero_description:
    "As a Software Developer specializing in React.js and Django REST framework, I focus on creating responsive web applications that enhance user experience and functionality. With a solid foundation in Java and Spring Boot, I have successfully delivered diverse projects, including healthcare applications and custom HRMS systems, showcasing my expertise in both frontend and backend development. Based in India, I am passionate about leveraging technology to drive innovative solutions and am committed to continuous learning in the fast-paced tech industry. I am always open to collaboration opportunities that create impactful digital experiences.",
  about_description: `Hello! My name is Md Zaigham, and I’m a dedicated <span className="bold">Software Developer</span>   with a Bachelor’s degree in Computer Science, where I proudly achieved a CGPA of 8.21. I have a genuine passion for technology and an unwavering commitment to continuous learning. My experience encompasses both front-end and back-end development, enabling me to craft dynamic, user-friendly web applications that tackle complex challenges effectively.

  I specialize in a diverse range of technologies, including Java, Spring Boot, and Hibernate for building robust applications, as well as Python and the Django REST Framework for developing powerful APIs. My expertise extends to AWS for cloud solutions and managing VPS servers, ensuring optimal performance and scalability. Additionally, I have experience in SEO analysis, enhancing website visibility and driving organic traffic. With a solid understanding of OOP concepts and proficiency in Ubuntu, I strive to deliver high-quality software solutions. I’m excited to connect and collaborate with like-minded individuals who share a passion for innovation and development, and I look forward to contributing to impactful projects in the tech industry!`,
  contact_description:
    "I’m passionate about creating innovative digital solutions and would love to hear from you. Whether you have a project in mind or need expert advice, let's connect. Reach out for inquiries, collaborations, or partnerships. Together, we’ll create something exceptional.",
};


export const experiences = [
  {
    company: "Pandoza Solutions",
    role: "Full Stack Developer",
    duration: "Sep 2023 - Present",
    description:
      "As a Full Stack Developer at Pandoza Solutions, I specialize in developing custom CRM and HRMS systems from scratch, handling both frontend and backend development along with creating intuitive user interfaces. In addition to coding, I am responsible for setting up and managing CI/CD pipelines, ensuring efficient and reliable deployments on VPS servers. My role involves optimizing performance, maintaining scalability, and delivering high-quality, tailored solutions that meet the unique needs of our clients in a fast-paced environment.",
    image: pandoza,
  },
  // Add more experience objects if needed
];

export const projects = [
  {
    title: "Custom CRM Management",
    description:
      "I engineered a full-featured CRM system from the ground up, leveraging React.js for the frontend, Django REST framework for the backend, and MySQL for the database. The system tracks employee records, tasks, teams, and manages client data, with role-based login and authentication. Additionally, I implemented deployment processes and manage the system on VPS servers, ensuring scalability, performance, and security for seamless operation.",
    image:
      crm,
    id: "01",
  },
  {
    title: "Comprehensive HR Management System",
    description:
      "I meticulously developed a comprehensive HR management system from the ground up, encompassing both frontend and backend development, along with a user-friendly interface. This robust solution empowers HR personnel to efficiently generate salary slips, offer letters, and relief letters while seamlessly managing employee data and salary information. To enhance security, I implemented JWT token-based authentication, ensuring the protection of sensitive employee data and providing a secure framework for user sessions. This system significantly streamlines HR processes and optimizes administrative efficiency.",
    image:
      hrms,
    id: "02",
  },
  {
    title: "A Dynamic React Native Movie Discovery App",
    description:
      `I developed a React Native mobile app that integrates the TMDB API to display trending movies and a comprehensive list of all available titles. The app includes advanced search functionality, allowing users to easily discover specific movies. This project showcases responsive design, efficient data fetching, and seamless user experience optimized for mobile platforms.`,
    image:
      movie,
    id: "03",
    link:"https://github.com/ZaighamCodes/movieApp-reactNative"
  },
];