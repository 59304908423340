import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { FaCoffee } from 'react-icons/fa'; // Using React Icons for the coffee cup

const BuyMeACoffee = () => {
  const textRef = useRef(null);

  useEffect(() => {
    // GSAP animation for rotating text around the coffee cup
    gsap.to(textRef.current, {
      rotation: 360,
      repeat: -1, // Infinite rotation
      duration: 5,
      ease: 'linear',
    });
  }, []);

  const handlePayment = () => {
    const upiUrl = 'upi://pay?pa=8210272603@ybl&pn=Md%20Zaigham&am=100&cu=INR';
    window.location.href = upiUrl;
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '60px',
          height: '60px',
          backgroundColor: 'var(--black)',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handlePayment}
      >
        <FaCoffee size={30} color="#ffffff" />
        <div
          ref={textRef}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            fontSize: '12px',
            color: '#000',
            transformOrigin: '50px 50px', // Ensures rotation is around the center
          }}
        >
        </div>
      </div>
    </div>
  );
};

export default BuyMeACoffee;
