import React, { useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./about.scss";
import WobbleText from "../animations/WobbleText";
import aboutimg from "../../assets/about.png";
import { content } from "../../static/Content";
// Register ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const leftSectionRef = useRef(null);
  const rightSectionRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  useLayoutEffect(() => {
    // Fade-up for left section (image)
    gsap.fromTo(
      leftSectionRef.current,
      { y: 50, opacity: 0 }, // Start from below (fade-up effect)
      {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: leftSectionRef.current,
          start: "top 80%", // When the top of the section hits 80% of the viewport
        },
      }
    );

    // Fade-up for right section (title and description)
    gsap.fromTo(
      rightSectionRef.current,
      { y: 50, opacity: 0 }, // Start from below (fade-up effect)
      {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: rightSectionRef.current,
          start: "top 80%", // When the top of the section hits 80% of the viewport
        },
      }
    );

    // Stagger animation for title and description
    gsap.fromTo(
      [titleRef.current, descriptionRef.current],
      { y: 50, opacity: 0 }, // Start from below (fade-up)
      {
        y: 0,
        opacity: 1,
        stagger: 0.2, // 0.2s delay between title and description
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: rightSectionRef.current,
          start: "top 80%",
        },
      }
    );
  }, []);

  return (
    <div className="about-parent parent" id="about">
      <div className="about-container container">
        <div className="about-left" ref={leftSectionRef}>
          <img src={aboutimg} alt="About Me" />
        </div>
        <div className="about-right" ref={rightSectionRef}>
          <div className="about-title title1" ref={titleRef}>
            About <WobbleText text="Me" />
          </div>
          <p ref={descriptionRef} className="about-description">
            Hello! My name is <h1 className="about-h1">Md Zaigham</h1>, and I’m a dedicated{" "}
            <span className="bold">Software Developer</span> with a Bachelor’s
            degree in <span className="bold">Computer Science,</span> where I
            proudly achieved a CGPA of <span className="bold">8.21. </span>I
            have a genuine passion for technology and an unwavering commitment
            to continuous learning. My experience encompasses both front-end and
            back-end development, enabling me to craft dynamic, user-friendly
            web applications that tackle complex challenges effectively. 
            <br/>
            <br/>
            
            I specialize in a diverse range of technologies, including{" "}
            <span className="bold">Java, Spring Boot, and Hibernate</span> for
            building <span className="bold">robust applications,</span> as well
            as{" "}
            <span className="bold">Python and the Django REST Framework</span>{" "}
            for developing powerful APIs. My expertise extends to{" "}
            <span className="bold">AWS</span> for cloud solutions and managing
            VPS servers, ensuring optimal performance and scalability.
            Additionally, I have experience in <span className="bold">SEO</span>{" "}
            analysis, enhancing website visibility and driving organic traffic.
            With a solid understanding of <span className="bold">OOP</span>{" "}
            concepts and proficiency in <span className="bold">Ubuntu</span>, I
            strive to deliver high-quality software solutions. I’m excited to
            connect and collaborate with like-minded individuals who share a
            passion for innovation and development, and I look forward to
            contributing to impactful projects in the tech industry!
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
