import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./contact.scss";
import { RiFacebookLine, RiLinkedinLine } from "react-icons/ri";
import { FaGithub, FaInstagram, FaWhatsapp } from "react-icons/fa";
import WobbleText from "../animations/WobbleText";
import { content } from "../../static/Content";
import axios from "axios";
// Register ScrollTrigger for GSAP
gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
  const formRef = useRef(null);
  const contactRefs = useRef([]);
  const [submissionStatus, setSubmissionStatus] = useState(false);
  const [formdata, setformdata] = useState({
    Name: "",
    Email: "",
    Contact: "",
    Message: "",
    MyDate:"",
    MyTime:""
  });
  // GSAP animation for the contact form elements
  useLayoutEffect(() => {
    const inputs = formRef.current.querySelectorAll(
      "input, textarea, button, .icon"
    );
    gsap.fromTo(
      inputs,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2, // Adds a delay between each input/element
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: formRef.current,
          start: "top 80%", // Start animation when the form is 80% in the viewport
        },
      }
    );

    gsap.fromTo(
      contactRefs.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.3, // Adds a delay between each element in the right section
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: contactRefs.current,
          start: "top 80%",
        },
      }
    );
  }, []);
// 



const getCurrentDateTime = () => {
  const now = new Date();

  // Get day, month, and year
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = now.getFullYear();

  // Get hours and minutes
  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, '0');

  // Determine AM or PM
  const amPm = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  // Format the date and time
  const formattedDate = `${day}-${month}-${year}`;
  const formattedTime = `${hours}:${minutes} ${amPm}`;
  // Set the state
  setformdata({...formdata,MyDate:formattedDate,MyTime:formattedTime})
};

useEffect(() => {
  getCurrentDateTime();
  const timer = setInterval(getCurrentDateTime, 60000);
  return () => clearInterval(timer);
}, []);

// 
  const Submit = async (e) => {
    e.preventDefault();
    const myformdata = new FormData();
    myformdata.append("Name", formdata.Name);
    myformdata.append("Email", formdata.Email);
    myformdata.append("Contact", formdata.Contact);
    myformdata.append("Message", formdata.Message);
    myformdata.append("Date", formdata.MyDate);
    myformdata.append("Time", formdata.MyTime);

    try {
      setSubmissionStatus(true);
      const response = await axios.post(
        "https://script.google.com/macros/s/AKfycbx6af-OOLwQMTxsKcVmidv0dA3gk4NyxV7N7ctbneTKYs9JPmwO41T_3YBNB2VbiDpr/exec",
        myformdata
      );
      setSubmissionStatus(false);
      setformdata({
        Name: "",
        Email: "",
        Contact: "",
        Message: "",
        MyDate:"",
        MyTime:""
      })
    } catch (err) {
      setSubmissionStatus(false);
      console.log(err);
    }
  };

  return (
    <div className="contact-parent parent" id="contact">
      <div className="contact-container container">
        <div className="contact-left">
          <form ref={formRef} action="" onSubmit={(e) => Submit(e)}>
            <input
              type="text"
              name="Name"
              placeholder="Your name *"
              value={formdata.Name}
              onChange={(e) =>
                setformdata({ ...formdata, Name: e.target.value })
              }
              required
            />
            <input
              type="email"
              name="Email"
              placeholder="Email *"
              value={formdata.Email}
              onChange={(e) =>
                setformdata({ ...formdata, Email: e.target.value })
              }
              required
            />
            <input
              type="text"
              name="Contact"
              placeholder="Contact number *"
              value={formdata.Contact}
              onChange={(e) =>
                setformdata({ ...formdata, Contact: e.target.value })
              }
              required
            />
            <textarea
              name="Message"
              placeholder="How can I help?"
              value={formdata.Message}
              onChange={(e) =>
                setformdata({ ...formdata, Message: e.target.value })
              }
            />
            <div className="button-box">
              <button className="cta-btn" type="submit">
                {submissionStatus ? "Submitting..." : "Contact"}
              </button>
              <div className="contact-icon-box">
                <a
                  href={content.linkedin_link}
                  target="__blank"
                  className="linkedin icon"
                >
                  <RiLinkedinLine />
                </a>
                <a
                  href={content.facebook_link}
                  target="__blank"
                  className="facebook icon"
                >
                  <RiFacebookLine />
                </a>
                <a
                  href={content.instagram_link}
                  target="__blank"
                  className="instagram icon"
                >
                  <FaInstagram />
                </a>
                <a
                  href={`https://wa.me/${content.contact_number}?text=Hello%20Md%20Zaigham,%20I'm%20interested%20in%20your%20services!`}
                  target="_blank" // Opens in a new tab
                  rel="noopener noreferrer" // Security best practice
                  className="whatsapp icon"
                >
                  <FaWhatsapp />
                </a>
                <a
                  href={content.github_link}
                  target="__blank"
                  className="whatsapp icon"
                >
                  <FaGithub />
                </a>
              </div>
            </div>
          </form>
        </div>
        <div className="contact-right">
          <h2
            className="contact-right-title title1"
            ref={(el) => (contactRefs.current[0] = el)}
          >
            Let's <span className="outlined-text">connect</span> <br /> & create
            something <WobbleText text="exceptional." />
            {/* Let’s connect and create something exceptional */}
          </h2>
          <p
            className="contact-description"
            ref={(el) => (contactRefs.current[1] = el)}
          >
            {content.contact_description}
          </p>
          <a
            href="mailto:mdzaigham27@gmail.com"
            className="contact-email handle-detail"
            ref={(el) => (contactRefs.current[2] = el)}
          >
            <span></span> {content.email}
          </a>
          <a
            href="tel:+918210272603"
            className="contact-number handle-detail"
            ref={(el) => (contactRefs.current[3] = el)}
          >
            {content.contact_number}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
