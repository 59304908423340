import React from "react";
import notfound from "../../assets/not.webp";
import "./notfound.scss"
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="parent notfound-parent">
      <div className="container notfound-container">
        <img src={notfound} alt="" className="notfound-img"/>
        <div className="bottom-box">
          <Link to="/" className="cta-btn">Back To Home</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
