import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

const WobbleText = ({ text , color}) => {
  const textRef = useRef(null);

  useEffect(() => {
    const textElement = textRef.current;

    const handleMouseOver = () => {
      const letters = textElement.querySelectorAll("span");
      letters.forEach((letter, index) => {
        // Create wobble effect for each letter
        gsap.fromTo(
          letter,
          { y: 0 }, // Start from original position
          {
            y: -10, // Move up
            duration: 0.4,
            ease: "power1.inOut",
            delay: index * 0.05, // Delay based on index
            yoyo: true,
            repeat: 1, // Repeat
          }
        );
      });
    };

    textElement.addEventListener("mouseover", handleMouseOver);
    return () => {
      textElement.removeEventListener("mouseover", handleMouseOver);
    };
  }, []);

  return (
    <h1 ref={textRef} className="wobble-text">
      {text.split("").map((letter, index) => (
        <span key={index} className="letter" style={{color: color ? "var(--white)" : "" }}>
          {letter}
        </span>
      ))}
    </h1>
  );
};

export default WobbleText;
