import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BsDownload } from "react-icons/bs";
import gsap from "gsap";
import "./navbar.scss";
import { content } from "../../static/Content";
const Navbar = () => {
  const logoRef = useRef(null);
  const linksRef = useRef([]);
  const ctaBtnRef = useRef(null);
  const ctaBtnRef2 = useRef(null);
  const mobileLinksRef = useRef([]); // For mobile links
  const [isOpen, setIsOpen] = useState(false); // State to manage burger toggle
  useEffect(() => {
    // Animation for logo (slide from left)
    gsap.fromTo(
      logoRef.current,
      { x: -100, opacity: 0 }, // Start position (offscreen)
      { x: 0, opacity: 1, duration: 0.6, ease: "power2.out" } // End position (onscreen)
    );

    // Staggered fade down for links
    gsap.fromTo(
      linksRef.current,
      { y: -50, opacity: 0 }, // Start position (above and invisible)
      {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 0.2,
        delay: 0.6,
        ease: "power2.out",
      } // End position (onscreen)
    );

    // Fade in for CTA button
    gsap.fromTo(
      ctaBtnRef.current,
      { y: -50, opacity: 0 }, // Start position
      { y: 0, opacity: 1, duration: 0.8, delay: 1.6, ease: "power2.out" } // End position
    );
  }, []);

  useEffect(() => {
    if (isOpen) {
      gsap.fromTo(
        mobileLinksRef.current,
        { x: -150, opacity: 0 }, // Start position (offscreen to the right)
        {
          x: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 1,
          ease: "power2.out",
        } // End position (onscreen)
      );

      gsap.fromTo(
        ctaBtnRef2.current,
        { x: -50, opacity: 0 }, // Start position
        { x: 0, opacity: 1, duration: 0.8, delay: 1.2, ease: "power2.out" } // End position
      );
    } else {
      // Optionally reset links position when closing navbar
      gsap.set(mobileLinksRef.current, { x: 50, opacity: 0 });
    }
  }, [isOpen]);
  const handleToggle = () => {
    setIsOpen(!isOpen); // Toggle the state
  };
  return (
    <div className="navbar-parent parent">
      <div className="navbar-container container">
        <Link to="/" className="logo" ref={logoRef}>
          <h1>Md Zaigham</h1>
        </Link>
        <div className="links-box">
          {["About Me", "Skills", "Projects", "Contact Me","Blogs"].map(
            (link, index) => {
              const paths = ["/#about", "/#skills", "/#projects", "/#contact","/blogs"];
              return (
                <a
                  key={index}
                  href={paths[index]} // Pass correct path for each link
                  ref={(el) => (linksRef.current[index] = el)}
                >
                  {link}
                </a>
              );
            }
          )}
        </div>
        <div className="download-btn">
          <a
            className="cta-btn"
            ref={ctaBtnRef}
            href={content.resume_link} // Adjust the path to your resume file
            download="resume.pdf"
          >
            Resume{" "}
            <span>
              <BsDownload />
            </span>
          </a>
        </div>

        <div className="svg-burger" onClick={handleToggle}>
          <svg
            className="hb"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 10"
            stroke={isOpen ? "#fff" : "#000"}
            strokeWidth=".6"
            fill="rgba(0,0,0,0)"
            strokeLinecap="round"
            style={{ cursor: "pointer" }}
          >
            <path
              d={
                isOpen
                  ? "M3,3L5,5L7,3M5,5L5,5M3,7L5,5L7,7"
                  : "M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7"
              }
              className={`burger-path ${isOpen ? "open" : ""}`}
            />
          </svg>
        </div>
      </div>

      <div className={`mobile-navbar-container ${isOpen ? "open" : ""}`}>
        <div className="mobile-wrapper">
          <div className="mobile-links">
            {["About Me", "Skills", "Projects", "Contact Me","Blogs"].map(
              (link, index) => {
                const paths = ["#about", "#skills", "#projects", "#contact","/blogs"];
                return (
                  <a
                    key={index}
                    href={paths[index]} // Pass correct path for each link
                    ref={(el) => (mobileLinksRef.current[index] = el)}
                    onClick={() => setIsOpen(false)}
                  >
                    {link}
                  </a>
                );
              }
            )}

           
          </div>

          <a
              className="cta-btn-rev"
              ref={ctaBtnRef2}
              href={content.resume_link} // Adjust the path to your resume file
              download="resume.pdf"
            >
              Resume{" "}
              <span>
                <BsDownload />
              </span>
            </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
