import React from "react";
import "./Blogs.scss";
import img from "../../assets/blogs/blog1.webp";
import readmore from "../../assets/readmore.png";
import { Link } from "react-router-dom";
const Blogs = () => {
  return (
    <div className="blogs-parent parent" id="blogs">
      <div className="blogs-container container">
        {/* <h2 className="title1 blogs-title">My blogs</h2> */}

        <div className="blogs-wrapper">
          <div className="blogs-card">
            <div className="blogs-card-left">
              <img src={img} />
            </div>
            <div className="blogs-card-right">
              <div className="blogs-count">01</div>
              <div className="blogs-title-heading title2">The Future of Software Development in India: Trends, Opportunities ...</div>
              <p className="blogs-description">
              India has firmly established itself as a global leader in the software development industry. With its skilled workforce, competitive costs, and growing technological advancements, the country has become a hub for companies seeking top-notch software solutions. Whether you’re a business looking for software outsourcing or a budding developer in India, this post will highlight the trends, opportunities, and future prospects of software development in the country...
              </p>
              <Link to="/blogDetail" target="__blank" className="blogs-button">
                <img src={readmore} alt="Read more" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
