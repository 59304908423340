import React from "react";
import Hero from "../../components/heroSection/Hero";
import "./home.scss";
import Projects from "../../components/projects/Projects";
import Skills from "../../components/skills/Skills";
import Experiance from "../../components/experiance/Experiance";
import About from "../../components/about/About";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/Footer";
import { Helmet } from "react-helmet";
import BuyMeACoffee from "../../components/buymecoffee/BuyMeCoffee";
import GsapSmoothScroll from "../../components/smoothscroll/SmoothScroll";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Md Zaigham | Software Developer & Full Stack Developer from India
        </title>
        <meta
          name="description"
          content="Md Zaigham - Expert Software Developer and Full Stack Developer from India. Specializing in web development, software solutions, and SEO analysis."
        />
        <meta
          name="keywords"
          content="Md Zaigham, Software Developer, Full Stack Developer, SEO Analyst, Web Development, Software Solutions, India"
        />
        <link rel="canonical" href="https://www.zaigham.online" />
        <meta
          property="og:title"
          content="Md Zaigham | Software Developer & Full Stack Developer from India"
        />
        <meta
          property="og:description"
          content="Md Zaigham - Expert Software Developer and Full Stack Developer from India. Specializing in web development, software solutions, and SEO analysis."
        />
        <meta property="og:url" content="https://www.zaigham.online" />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Md Zaigham | Software Developer & Full Stack Developer from India"
        />
        <meta
          name="twitter:description"
          content="Md Zaigham - Expert Software Developer and Full Stack Developer from India. Specializing in web development, software solutions, and SEO analysis."
        />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Person",
            "name": "Md Zaigham",
            "jobTitle": "Software Developer",
            "worksFor": {
              "@type": "Organization",
              "name": "Pandoza Solutions"
            },
            "url": "https://www.zaigham.online",
            "sameAs": [
              "https://www.linkedin.com/in/md-zaigham-97b7ba259/",
              "https://github.com/ZaighamCodes",
              "https://x.com/md_zaigham"
            ],
          }
          `}
        </script>
      </Helmet>
      <div className="home-parent">
        <BuyMeACoffee />
        <Hero />
        <Skills />
        <Experiance />

        <About />
        <Projects />
        <Contact />
      </div>
    </>
  );
};

export default Home;
