// ClientRoutes.js
import { Outlet } from "react-router-dom";
import ScrollToTop from "../components/scrolltotop/ScrollToTop";
import Navbar from "../components/navbar/Navbar";
import Home from "../pages/home/Home";
import NotFound from "../pages/notfound/NotFound";
import Footer from "../components/footer/Footer";
import Blogs from "../pages/blogs/Blogs";
import BlogDetail from "../pages/blogDetail/BlogDetail";

const ClientLayout = ({children}) => {
  return (
    <>
      <Navbar />
      <ScrollToTop />
     {children}
     <Footer/>
    </>
  );
};


const clientRoutes = [
  {
    path: "/",
    element: (
      <ClientLayout>
        <Home />
      </ClientLayout>
    ),
  },
  {
    path: "/blogs",
    element: (
      <ClientLayout>
        <Blogs />
      </ClientLayout>
    ),
  },
  {
    path: "/blogDetail",
    element: (
      <ClientLayout>
        <BlogDetail />
      </ClientLayout>
    ),
  },
  {
    path: "/*",
    element: (
      <NotFound/>
    ),
  },
];

export default clientRoutes;
