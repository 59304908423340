import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import adminRoutes from "./routes/AdminRoutes";
import clientRoutes from "./routes/ClientRoutes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {clientRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}

          {adminRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
