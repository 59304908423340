import React, { createContext, useContext } from "react";
import { gsap } from "gsap";

// Create the context
const SmoothScrollContext = createContext();

// Create a provider component
export const SmoothScrollProvider = ({ children }) => {
  const scrollToSection = (target) => {
    gsap.to(window, { scrollTo: target, duration: 1.2, ease: "power2.inOut" });
  };

  return (
    <SmoothScrollContext.Provider value={scrollToSection}>
      {children}
    </SmoothScrollContext.Provider>
  );
};

// Custom hook to use the Smooth Scroll context
export const useSmoothScroll = () => {
  return useContext(SmoothScrollContext);
};
