// AdminRoutes.js
import Login from '../server/pages/Login';

const adminRoutes = [
  {
    path: '/admin',
    element: <Login />,
  },
];

export default adminRoutes;
