import React, { useEffect, useRef } from "react";
import "./skills.scss";
import WobbleText from "../animations/WobbleText";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import git from "../../assets/skills/git.png";
import github from "../../assets/skills/github.png";
import python from "../../assets/skills/python.png";
import java from "../../assets/skills/java.png";
import django from "../../assets/skills/django.png";
import spring from "../../assets/skills/spring.webp";
import sass from "../../assets/skills/sass.png";
import aws from "../../assets/skills/aws.png";
import sql from "../../assets/skills/sql.png";
import react from "../../assets/skills/react.png";
import postman from "../../assets/skills/postman.png";
import javascript from "../../assets/skills/javascript.png";

// Register ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const Skills = () => {
  const skillsRef = useRef(null);
  const titleRef = useRef(null);
  
  const skills = [
    { name: "Java", image: java,link:"https://dev.java/learn/" },
    { name: "Python", image: python,link:"https://python.land/python-tutorial" },
    { name: "JavaScript", image: javascript , link:"https://developer.mozilla.org/en-US/docs/Web/JavaScript"},
    { name: "Rest Api", image: django, link:"https://docs.djangoproject.com/en/5.1/" },
    { name: "Spring Boot", image: spring,link:"https://docs.spring.io/spring-boot/index.html" },
    { name: "React Js", image: react , link:"https://react.dev/learn"},
    { name: "Sass/Scss", image: sass,link:"https://sass-lang.com/guide/" },
    { name: "My SQL", image: sql,link:"https://www.w3schools.com/MySQL/default.asp" },
    { name: "AWS", image: aws, link:"https://docs.aws.amazon.com/" },
    { name: "Git", image: git , link:"https://git-scm.com/docs"},
    { name: "GitHub", image: github,link:"https://docs.github.com/en" },
    { name: "Postman", image: postman, link:"https://learning.postman.com/docs/introduction/overview/" },
  ];

  useEffect(() => {
    // Fade down and stagger skills cards
    const cards = gsap.utils.toArray(".skills-card");

    gsap.from(cards, {
      y: 50,
      opacity: 0,
      stagger: 0.2,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: skillsRef.current,
        start: "top 80%", // Adjust based on when you want the animation to start
      },
    });

    // Fade down the skills title
    gsap.from(titleRef.current, {
      y: 20,
      opacity: 0,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: skillsRef.current,
        start: "top 90%",
      },
    });
  }, []);

  return (
    <div className="skills-parent parent" id="skills" ref={skillsRef}>
      <div className="skills-container container">
        <h2 className="skills-title title1" ref={titleRef}>
          My{" "}
          <span>
            {" "}
            <WobbleText text="Skills" />{" "}
          </span>
        </h2>
        <div className="skills-box-wrapper">
          {skills.map((data, index) => (
            <a href={data.link} className="skills-card" key={index} target="__blank">
              <div className="card-top">
                <img src={data.image} alt={data.name} />
              </div>
              <span className="card-bottom">{data.name}</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
