import React from 'react';
import './login.scss';
import img from "../../assets/login.webp"
const Login = () => {
  return (
    <div className="login-parent parent">
      <div className="login-container container">
        <div className="login-left">
          {/* Optional: Add any content or images for the left side */}
          <img src={img} alt="" />
        </div>
        <div className="login-right">
          <h1 className="login-title">Login</h1>
          <form className="login-form">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" id="password" required />
            </div>
            <button type="submit" className="cta-btn">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
