import React, { useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./projects.scss";
import WobbleText from "../animations/WobbleText";
import readmore from "../../assets/readmore.png";
import { projects } from "../../static/Content";
// Register ScrollTrigger for GSAP
gsap.registerPlugin(ScrollTrigger);

const Projects = () => {
  const projectRefs = useRef([]);
  const titleRef = useRef(null);

  // Dummy project data
 

  // GSAP animation
  useLayoutEffect(() => {
    // Fade-down animation for the project section when it comes into view
    gsap.fromTo(
      projectRefs.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        stagger: 0.2, // Delay between each project card
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: projectRefs.current,
          start: "top 80%", // When the top of the section hits 80% of the viewport
        },
      }
    );

    // Stagger fade-down for project title
    gsap.fromTo(
      titleRef.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        ease: "power2.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%", // When the title comes into view
        },
      }
    );
  }, []);

  return (
    <div className="projects-parent parent" id="projects">
      <div className="projects-container container">
        <h2 className="title1 project-title" ref={titleRef}>
          My <WobbleText text="Projects" color={true} />
        </h2>

        <div className="project-wrapper">
          {projects.map((project, index) => (
            <div
              className="projects-card"
              key={index}
              ref={(el) => (projectRefs.current[index] = el)}
            >
              <div className="project-card-left">
                <img src={project.image} alt={project.title} />
              </div>
              <div className="project-card-right">
                <div className="project-count">{project.id}</div>
                <div className="project-title-heading title2">
                  {project.title}
                </div>
                <p className="project-description">{project.description}</p>
                {
                  project.link && <a href={project.link} target="__blank" className="project-button">
                  <img src={readmore} alt="Read more" />
                </a>
                }
                
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
