import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import { experiences } from "../../static/Content";
import WobbleText from "../animations/WobbleText";
import "./experiance.scss";
// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const Experiance = () => {
  const expCardsRef = useRef([]);
  const titleRef = useRef(null);

  // Experience data


  useLayoutEffect(() => {
    // Animate experience cards
    if (expCardsRef.current.length) {
      gsap.fromTo(
        expCardsRef.current,
        { y: -100, opacity: 0 }, // Start from above for fade down effect
        {
          y: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: expCardsRef.current[0],
            start: "top 80%", // Start animation when 80% of the card is visible
          },
        }
      );
    }

    // Animate title
    if (titleRef.current) {
      gsap.fromTo(
        titleRef.current,
        { y: -20, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "power2.out",
          scrollTrigger: {
            trigger: titleRef.current,
            start: "top 90%",
          },
        }
      );
    }
  }, [experiences]);

  return (
    <div className="experiance-parent parent">
      <div className="experiance-container container">
        <h2 className="title1 experiance-title" ref={titleRef}>
          My <WobbleText text="Experiance" color={true} />
        </h2>

        <div className="experiance-card-parent"  id="experiance">
          {experiences.map((exp, index) => (
            <div
              className="experiance-card"
              key={index}
              ref={(el) => (expCardsRef.current[index] = el)}
            >
              <div className="experiance-top">
                <div className="experiance-top-left">
                  <img src={exp.image} alt={exp.company} />
                  <h2 className="exp-title title1">
                    {exp.role} at <span>{exp.company}</span>
                  </h2>
                </div>
                <div className="experiance-top-right">{exp.duration}</div>
              </div>

              <p className="experiance-bottom">{exp.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experiance;
