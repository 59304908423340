import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SmoothScrollProvider } from './components/animations/SmoothScroll';
import ReactGA from "react-ga4";
const root = ReactDOM.createRoot(document.getElementById('root'));

// Initialize Google Analytics
const GA_MEASUREMENT_ID = 'G-X26V00QSGN'; // Replace with your measurement ID
ReactGA.initialize(GA_MEASUREMENT_ID);
root.render(
  <SmoothScrollProvider>
    <App />
  </SmoothScrollProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
