import React from 'react'
import "./footer.scss"
const Footer = () => {
  return (
    <div className="parent footer-parent">
        <div className="container footer-container">
            <h2>Md Zaigham</h2>
            <p>© 2024 | Md Zaigham. All Right & Wrongs Reserved.</p>
        </div>
    </div>
  )
}

export default Footer